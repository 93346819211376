import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'
import { tryExceptAwait } from '@/utils'
import userEndpoints from '@/api/user'
import { DRAFT, OFFERED, OK } from '@/constants'
import orderApi from '@/api/orders'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.onError(async (error) => {
  // Reload if chunk cannot be found, or if there are redirect issues.
  // Check timestamp to prevent infinite reloads.
  const timestamp = router?.currentRoute?.query?.timestamp
  if (
    !timestamp &&
    (/loading .*chunk .* failed/i.test(error.message.toLowerCase()) ||
      /redirected when going from .* to .* via a navigation guard/i.test(
        error.message.toLowerCase()
      ))
  ) {
    const currentUrl = new URL(location.href)
    currentUrl.searchParams.delete('timestamp')
    currentUrl.searchParams.append('timestamp', Date.now())
    location.href = currentUrl.href
  }
})

// Check query parameters for order data from external sources.
// If this is the case, return the route object that should be
// provided to next() in the beforeEach guard.
const checkExternalOrder = async (to) => {
  if (to.query?.external_order_id) {
    const externalOrderId = to.query?.external_order_id
    const externalOrderIdClaim = to.query?.external_order_id_claim
    const { data, status } = await tryExceptAwait(orderApi.updateExternal, [
      {
        external_order_id: externalOrderId,
        external_order_id_claim: externalOrderIdClaim
      }
    ])
    if (status === OK) {
      if (data.status === DRAFT) {
        return { name: 'products' }
      } else if (data.status === OFFERED) {
        return {
          name: 'offer',
          query: {
            status: 'offered',
            reference_code: externalOrderId,
            edit_single_order: true
          }
        }
      }
    }
  }
}

router.beforeEach(async (to, from, next) => {
  const token = store.getters['user/getToken']
  let validToken = false
  if (to.redirectedFrom?.startsWith('/pdf/')) {
    // redirect old /pdf/ urls to /documents/, can be removed later
    window.location.href = to.redirectedFrom.replace('/pdf/', '/documents/')
  }
  if (token) {
    // we have a token, check if it is valid
    const { status } = await tryExceptAwait(userEndpoints.verifyToken, [{ token }])
    if (status === OK) {
      validToken = true
    } else {
      // try to use the refresh token
      await store.dispatch('user/refreshToken').then((status) => {
        validToken = status === 2
      })
    }
  }

  if (validToken) {
    // always reset order price type to default user price type on route change
    store.dispatch('order/setDefaultPriceType')
    // check if order data from external sources has been passed
    const externalOrderAction = await checkExternalOrder(to)
    if (externalOrderAction) {
      return next(externalOrderAction)
    }
    if (to.name === 'login') {
      // redirect users with valid token from login to default page
      const nextPage = to.query?.next || {
        name: store.getters['user/getDefaultPageName']
      }
      return next(nextPage)
    }
  } else if (['login', 'forgot-password', 'recover-password'].indexOf(to.name) === -1) {
    // redirect users with invalid token to login page
    return next({ name: 'login' })
  }
  next()
})

router.afterEach((to) => {
  if (to.meta?.pageTitle) {
    document.title = to.meta.pageTitle
  }
})

export default router
